import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
  }
`;

export const LeftPanel = styled.div`
  padding: 41px 36px;
  color: ${({ theme }) => theme.colors.dark};
  position: relative;
  flex: 0 0 58%;
  @media (min-width: 1280px) {
    padding: 5.5rem 10rem;
  }
`;

export const RightPanel = styled.div`
  position: relative;
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
  position: relative;
`;

export const Headline = styled.div`
  width: 34rem;
  margin-right: 0;
  @media (min-width: 1280px) {
    margin-right: 4.2rem;
  }
`;

export const Title = styled(animated.p)`
  font-size: 4rem;
  font-weight: bold;
  font-family: 'Sofia Pro', sans-serif;
  @media (min-width: 1280px) {
    font-size: 8rem;
  }
`;

export const Description = styled(animated.p)`
  font-size: 1.4rem;
  letter-spacing: 0.42px;
  font-weight: normal;
`;

export const BellImg = styled(animated.img)`
  position: absolute;
  right: 0;
  top: -25%;
  height: 10.8rem;
  width: 10.8rem;
  @media (min-width: 1280px) {
    height: 13.8rem;
    width: 13.8rem;
    position: relative;
  }
`;

export const Form = styled.form`
  & > * {
    margin-bottom: 3.8rem;
  }
`;
export const FormController = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1280px) {
    width: 43rem;
  }
`;

export const Label = styled.label`
  font-size: 1.6rem;

  input:focus ~ & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Input = styled.input`
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${({ theme }) => theme.colors.dark};
  padding: 0.6rem 0;
  font-size: 1.6rem;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 1280px) {
    border-width: 0 0 2px;
  }
`;

export const Area = styled.textarea`
  outline: 0;
  border-width: 0 0 1px;
  border-color: ${({ theme }) => theme.colors.dark};
  padding: 0.6rem 0;
  font-size: 1.6rem;
  resize: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 1280px) {
    border-width: 0 0 2px;
  }
`;

export const SendBtn = styled.button`
  padding: 1.2rem 4.4rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 10rem;
  box-shadow: 0px 3px 10px #ed672b99;
  width: 155px;
  font-size: 20px;
  margin-top: 5.4rem;
  cursor: pointer;

  &:disabled {
    background-color: #ed672b70;
    cursor: not-allowed;
  }
`;

export const SocialNetworks = styled(animated.div)`
  margin: 8rem 0 5rem 0;
`;

export const SocialTitle = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  width: 100px;
  color: ${({ theme }) => theme.colors.info};
  @media (min-width: 1280px) {
    font-size: 2rem;
    width: 100%;
    font-weight: normal;
  }
`;

export const SocialIconBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  margin-top: 9.5px;
`;

export const WomanImg = styled(animated.img)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 58%;
`;

export const Maps = styled(animated.iframe)`
  width: 100%;
  height: 550px;
  @media (min-width: 1280px) {
    width: 100%;
    height: 100%;
  }
`;

export const Footer = styled(animated.footer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 1rem 3.6rem;
  background-color: ${({ theme }) => theme.colors.info};
  border-radius: 2rem 2rem 0 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1280px) {
    height: 24.3rem;
    padding: 2.5rem 10% 1.1rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  gap: 0.1rem;
  margin-bottom: 19px;
  @media (min-width: 1280px) {
    margin-bottom: 0;
    gap: 4%;
  }
`;

export const Col = styled.div`
  display: flex;
  width: 168px;
  flex-direction: column;
  flex: 1;
  @media (min-width: 1280px) {
    width: auto;
  }
`;

export const FooterTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  @media (min-width: 1280px) {
  }
`;

export const FooterText = styled.p`
  max-width: 21.6rem;
  width: auto;
  font-weight: ${({ variant }) => (variant ? 'bolder' : 'normal')};
  font-size: ${({ variant }) => (variant ? '1.6rem' : '1.4rem')};
  color: ${({ theme, variant }) => (variant ? theme.colors.dark : 'inherit')};

  @media (min-width: 1280px) {
    font-size: 1.6rem;
  }
`;

export const Legal = styled.p`
  font-size: 1.1rem;
  font-weight: 200;
  text-align: center;
`;

export const MessageContainer = styled.div`
  position: absolute;
  top: 20%;
  right: 40%;
`;

export const MassageImg = styled.img`
  position: absolute;
`;

export const EmailError = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.danger};
`;
